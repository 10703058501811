import React from 'react'
import { TitleComponent } from "../../Common/TitleComponent";
import Search from "stringquery";
import { get } from "../../../../Model/Network/Config/Axios";
import Sidebar from './Sidebar'
import BookingDetails from './BookingDetails'
import { NotesComponent } from "../../Common/NotesComponent";
import Model from './Model';

import { ChooseTraveller } from "../../Common/ChooseTraveller";
import { showToast } from "../../../libs/Toast/ToastCallback";

class View extends React.Component {

	constructor(props) {
		super(props)
		this.presentor = props.presentor;
		this.presentor.setView(this);
		this.Model = new Model();
		this.Disposables = [];

		this.init()
	}

	state = {
		rooms: [],
		travellers: {

		},
		loading: false,
		checkIn: "",
		checkOut: "",
		cityName: '',
		hotel: undefined,
		selectTraveller: undefined,
		hotelHold: undefined
	}

	init = () => {
		const params = Search(this.props.location.search);
		if (params.sessionId) {
			this.Disposables.push(get(`/config/${params.sessionId}/session`, (e, r) => {
				if (r) {
					let counts = r.rooms;
					let count = 0;
					counts.forEach(item => {
						count = count + item;
					})
					let travellers = {

					}
					r.travellers.forEach((value, index) => {
						travellers[index + 1] = value;
					})
					this.setState({
						...r,
						count,
						travellers
					})
				} else {
					showToast({
						type: 'error',
						message: e
					})
				}
			}))
		}
		if (params.requestId) {
			this.Disposables.push(get(`/hotels/request/${params.requestId}`, (e, r) => {
				if (r) {
					this.setState({
						...this.state,
						travellers: {
							"1": {
								name: r.employee.name,
								type: 'EMPLOYEE',
								reference: r.employee.code
							}
						}
					})
				}
			}))
		}
	}


	componentWillUnmount() {
		this.Disposables.forEach(item => {       // For View.js init funnction
			if (item.isCancellable())
				item.cancel()
		})

		this.presentor.destroy();
	}


	onHold = hotelHold => {
		this.setState({
			...this.state,
			hotelHold,
			loading: false
		})
	}

	mapTravellers = () => {
		let count = this.state.count;
		let data = []
		for (var i = 1; i <= count; i++) {
			data.push(
				i
			)
		}
		return (
			<div>
				{data.map(value => {
					return (
						<div onClick={() => {
							if (!this.state.hotelHold) {
								this.setState({
									...this.state,
									selectTraveller: value
								})
							}
						}} key={value} style={{
							display: 'flex',
							marginTop: 15,
							alignItems: 'center',
							background: "#fafafa",
							border: '1px solid #eee',
							borderRadius: 22,
							position: 'relative',
							padding: '12px 20px',
							width: 350,
							cursor: 'pointer',
							flexDirection: 'row'
						}}>
							<p className="label-back" style={{
								fontWeight: 400,
								color: "#555555",
								fontSize: 13,
								position: 'absolute',
								top: -8,
								zIndex: 11,
								left: 20,
								marginRight: 10
							}}>
								Traveller {value}
							</p>
							<p style={{
								fontWeight: 600,
								color: "#454545",
								fontSize: 15,
							}}>{this.state.travellers[value] ? this.state.travellers[value].name : "Add Traveller"}</p>
						</div>
					)
				})}
			</div>
		)
	}

	onBook = r => {
		this.props.history.push(`/app/hotel/tickets/${r.transactionId}`)
	}

	_continue = () => {
		const params = Search(this.props.location.search);
		let count = this.state.count;
		let travellers = this.state.travellers;
		let data = []
		for (var i = 1; i <= count; i++) {
			if (!travellers[i]) {
				showToast({
					type: "error",
					message: `Please select traveller ${i}`
				})
				return;
			} else {
				data.push(travellers[i])
			}
		}
		data = {
			travellers: data,
			sessionId: params.sessionId,
			searchId: params.searchId
		}

		this.setState({
			...this.state,
			loading: true
		})
		this.presentor.continue(data)
	}

	_book = () => {
		const params = Search(this.props.location.search);
		let data = {
			sessionId: params.sessionId,
			searchId: params.searchId
		}
		this.setState({
			...this.state,
			loading: true
		})
		this.presentor.book(data)
	}

	render() {
		let hotel = this.state.hotel;
		if (hotel && hotel.map)
			hotel = hotel.map;
		return (
			<div className="border-top">
				<div className="side-container" style={{ borderTop: 'none' }}>
					<TitleComponent subtitle="Hotels" title="Complete Booking" />
					<div style={{
						marginTop: 30
					}}>
						{
							hotel ?
								<BookingDetails rooms={this.state.rooms} checkOut={this.state.checkOut} checkIn={this.state.checkIn} hotel={hotel} /> : null
						}
						<p style={{
							fontSize: 21,
							fontWeight: 600,
							color: "#454545",
							marginTop: 28,
							marginBottom: 18
						}}>
							Traveller Details
						</p>
						<p style={{
							marginBottom: 12,
							fontSize: 14,
							color: "#898989"
						}}>
							Please add travellers for this selected booking.
						</p>
						{
							this.mapTravellers()
						}
						{
							this.state.loading ? <p style={{
								marginTop: 30,
								boxShadow: "1px 1px 1px rgba(1,1,1,.05)",
								width: 280,
								height: 45,
								borderRadius: 22,
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								color: "white",
								cursor: 'pointer',
								textTransform: 'uppercase',
								background: "#7558F6"
							}}>
								Loading....
							</p> : this.state.hotelHold ?
									<p onClick={this._book} style={{
										marginTop: 30,
										boxShadow: "1px 1px 1px rgba(1,1,1,.05)",
										width: 280,
										height: 45,
										borderRadius: 22,
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										color: "white",
										cursor: 'pointer',
										textTransform: 'uppercase',
										background: "#7558F6"
									}}>
										Pay Rs {this.state.hotelHold.amount} to Book
								</p> :
									<p onClick={this._continue} style={{
										marginTop: 30,
										boxShadow: "1px 1px 1px rgba(1,1,1,.05)",
										width: 280,
										height: 45,
										borderRadius: 22,
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										color: "white",
										cursor: 'pointer',
										textTransform: 'uppercase',
										background: "#1ab64f"
									}}>
										CONTINUE TO BOOK
								</p>
						}
						<div style={{
							marginTop: 60
						}}>
							<NotesComponent notes={[
								"Traveller One is primary traveller. Booking will happen in name of primary travel and booking details will be triggered to primary traveller."
							]} />
						</div>
					</div>
				</div>
				<div className="right-side-bar">
					{
						hotel ?
							<Sidebar rooms={this.state.rooms} checkOut={this.state.checkOut} checkIn={this.state.checkIn} hotel={hotel} /> : null
					}
				</div>
				<ChooseTraveller callback={data => {
					let key = this.state.selectTraveller;
					let travellers = this.state.travellers;
					travellers[key] = data;
					this.setState({
						...this.state,
						travellers,
						selectTraveller: undefined
					})
				}} close={() => {
					this.setState({
						...this.state,
						selectTraveller: undefined
					})
				}} shown={this.state.selectTraveller !== undefined} />
			</div>
		)
	}

}

export default View