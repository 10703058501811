import React from 'react';
import { TableActions } from '../../../libs/Table/TableAction';
import { KeyValue } from '../../Common/KeyValue';
import { LunaPopUp } from '../../Common/Luna';



class CabRequest extends React.Component {



    render() {
        let { item } = this.props;
        let actions = [];
        actions.push({
            name: 'View',
            click: () => { }
        })
        if (item.status === 'PENDING') {
            actions.push({
                name: 'Close',
                click: () => { }
            })
        }
        return (
            <div>
                <div className="fintrip-department-item table-item" style={{
                    borderBottom: "1px solid #dedede",
                    display: 'flex',
                    cursor: 'pointer',
                    flexDirection: 'row',
                    padding: '12px 0'
                }}>
                    <div style={{ flex: 1, alignItems: 'center' }} className="linear-layout horizontal">
                        <div>
                            <img src={item.employee.image} alt="employee" style={{
                                width: 30, height: 30, borderRadius: 15,
                                marginRight: 8
                            }} />
                        </div>
                        <div>
                            <p className="top">{item.employee.name}</p>
                            <p className="bottom">{item.employee.email}</p>
                            <p className="bottom">{item.employee.code}</p>
                        </div>
                    </div>
                    <div style={{
                        flex: 1
                    }}>
                        <p style={{
                            fontWeight: 700,
                            fontSize: 14,
                            textAlign: 'center'
                        }} className="top">Cab</p>
                        <p style={{
                            textAlign: 'center'
                        }} className="bottom">
                            {item.originName} - {item.destinationName}
                        </p>
                        <p style={{
                            textAlign: 'center'
                        }} className="bottom">
                            {item.returnDate ? `${item.departureDate} - ${item.returnDate}` : `${item.departureDate}`}
                        </p>
                    </div>
                    <div style={{ flex: 1.4 }} className="right-actions">
                        <TableActions items={actions} />
                        <p onClick={() => {
                            if (this.props.open && this.props.open === item.id) {
                                this.props.onCloseOpen()
                            } else {
                                this.props.onSetOpen(item.id);
                            }
                        }} className="hoverable">{this.props.open === item.id ? "Close" : "View"}</p>

                        <p onClick={() => {
                            if (this.props.requestId && this.props.requestId === item.id) {
                                this.props.onCloseRequestId()
                            } else {
                                this.props.onSetRequestId(item.id);
                            }
                        }} className="hoverable">{this.props.requestId === item.id ? "Close Chat" : "Chat"}</p>

                    </div>

                </div>
                {
                    this.props.open === item.id ?
                        <div style={{
                            backgroundColor: '#f2f2f2',
                            padding: 20
                        }}>
                            <div style={{
                                display: 'flex',
                                flex: 1
                            }}>
                                <KeyValue title="Booking Type" value={item.bookingType} />
                                <KeyValue title="Destination Name" value={item.destinationName} />
                                <KeyValue title="Origin Name" value={item.originName} />

                            </div>
                            <div style={{
                                display: 'flex',
                                flex: 1,
                                marginTop: 10
                            }}>
                                <KeyValue title="Departure Date" value={item.departureDate} />
                                <KeyValue title="Status" value={item.status} />
                                <KeyValue title="Type" value={item.type} />


                            </div>
                            <div>
                            </div>
                        </div> : null
                }

                {
                    this.props.requestId ? <LunaPopUp
                        requestId={this.props.requestId} /> : null

                }
            </div>
        )
    }
}

export default CabRequest;