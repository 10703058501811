import React from 'react'
import { TitleComponent, TitleComponent2 } from "../../Common/TitleComponent";
import { NormalTable } from "../../../Tables/NormalTable/NormalTable";
import { TableActions } from "../../../libs/Table/TableAction";
import { Modal } from "../../../libs/Modal/Modal";
import { FloatInput } from "../../../libs/FloatLabelInput";
import { ShownSelect } from "../../../libs/Select/index3";
import { showToast } from "../../../libs/Toast/ToastCallback";
import Moment from 'moment';
import { FilterNames } from '../../Common/Filter';

class View extends React.Component {

	constructor(props) {
		super(props);
		this.presentor = props.presentor;
		this.presentor.setView(this)
	}

	componentDidMount() {
		this.presentor.init()
	}

	state = {
		guests: [],
		gender: {
			value: "MALE",
			label: "Male"
		},
		list: []
	}

	load = r => {
		let guest = r.guests.map(val => {
			return {
				name: val.firstName + " " + val.lastName,
				...val
			}
		})
		this.setState({
			guests: guest
		})
	}

	componentWillUnmount() {
		this.presentor.destroy();
	}


	item = item => {
		let click = () => {
			this.props.history.push(
				`/app/travellers/guest/${item.id}`
			)
		}
		return (
			<div key={item.id} style={{ paddingLeft: 0, paddingRight: 0 }}
				className="border-bottom fintrip-department-item table-item">
				<div className="linear-layout horizontal"
					style={{ flex: 1, cursor: 'pointer' }}>
					<div style={{ flex: 2, alignItems: 'center' }} className="linear-layout horizontal">
						<div>
							<p className="top">{item.firstName} {item.lastName}</p>
							<p className="bottom">{item.emailAddress}</p>
						</div>
					</div>
					<div className="flex-2">
						{item.mobile}
					</div>
					<div className="flex-1">
						{item.gender}
					</div>
				</div>
				<div style={{ width: 200 }} className="right-actions">
					<TableActions items={[
						{
							name: "Details",
							click
						}]} />
					<p onClick={click} className="hoverable">Details</p>
				</div>
			</div>
		)
	}

	clearAndInit = () => {
		this.setState({
			...this.state,
			addModal: false
		})
		this._clearValues()
		this.presentor.init()
	}

	_addGuest = () => {
		let firstName = this._firstName.value();
		let lastName = this._lastName.value();
		let emailAddress = this._emailAddress.value();
		let mobileNumber = this._mobileNumber.value();
		let gender = this.state.gender.value;
		let dob = this._dob.value();
		dob = Moment(dob).valueOf();
		let data = {
			name: {
				first: firstName,
				last: lastName
			},
			email: emailAddress,
			mobile: mobileNumber,
			gender,
			dob
		}
		if (!firstName) {
			showToast(
				{
					type: 'error',
					message: "Please enter a valid first name"
				}
			)

		}
		if (!lastName) {
			showToast(
				{
					type: 'error',
					message: "Please enter a valid last name"
				}
			)

		}
		if (!emailAddress) {
			showToast(
				{
					type: 'error',
					message: "Please enter a valid email address"
				}
			)

		}
		if (!mobileNumber) {
			showToast(
				{
					type: 'error',
					message: "Please enter a valid mobile number"
				}
			)

		}

		if (!dob) {
			showToast({
				type: 'error',
				message: 'Please enter a valid Date of Birth'
			})
			return
		}

		if (mobileNumber) {
			if (mobileNumber.length !== 10) {
				showToast({
					type: 'error',
					message: 'Please enter 10 digit mobile Number'
				})
				return

			}
		}


		this.presentor.addGuest(data)
	}

	_clearValues = () => {
		this._firstName.setValue("")
		this._lastName.setValue("")
		this._emailAddress.setValue("")
		this._mobileNumber.setValue("")
		this._dob.setValue("")
	}


	updateList = (item) => {
		let list = this.state.list;
		let isItem = false
		this.state.list.forEach(val => {
			if (val.code === item.code) {
				isItem = true
			}
		})
		if (!isItem) {
			list.push(item);
		}

		this.setState({
			list
		}, () => this.filterEmployee())
	}

	removeList = (item) => {
		let items = this.state.list;
		items = items.filter(i => i.name !== item.name)
		this.setState({
			list: items
		}, () => this.filterEmployee())
	}

	filterEmployee = () => {

		let employee = this.state.guests;
		if (this.state.list.length > 0) {

			return employee.filter(item1 => {
				let isFilter = false
				this.state.list.forEach(item2 => {

					if (item2.name === item1.name) {
						isFilter = true
					}
				})
				return isFilter;
			})
		}
		return employee;
	}




	render() {

		let data = this.filterEmployee();
		return (
			<div className="side-container">
				<TitleComponent add={() => {
					this.setState({
						...this.state,
						addModal: true
					})
				}} subtitle="Travellers" title="Guests" />
				<div style={{
					marginTop: 50
				}}>
					<FilterNames removeList={this.removeList} list={this.state.list} employees={this.state.guests} filterEmployee={this.updateList} />

					<TitleComponent2 title="All Guests" />
					<NormalTable headers={[
						{
							key: "employee",
							name: "Details",
							weight: 2
						},
						{
							key: "mobile",
							name: "Mobile Number",
							weight: 2
						},
						{
							key: "gender",
							name: "Gender",
							weight: 1
						},
						{
							key: "actions",
							name: "",
							width: 200
						}
					]} data={data} pagination={true} mapItem={this.item} />
				</div>
				<Modal buttonName="Add Guest" onSave={this._addGuest} title="Add Guest"
					des="Please add the details in below field to add a new guest." close={() => {
						this.setState({
							addModal: false
						})
						this._clearValues()
					}} style={{
						save: {
							width: '100%',
							textAlign: 'center',
							fontSize: 17,
							padding: "12px 20px",
							background: "#7558F6"
						},
					}} show={this.state.addModal}>
					<FloatInput title="First Name" type="text"
						handleRef={ref => this._firstName = ref} />
					<FloatInput title="Last Name" type="text"
						handleRef={ref => this._lastName = ref} />
					<FloatInput title="Email Address" type="text"
						handleRef={ref => this._emailAddress = ref} />
					<FloatInput title="Mobile Number" type="text"
						handleRef={ref => this._mobileNumber = ref} />
					<FloatInput title="Date of Birth" type="date"
						handleRef={ref => this._dob = ref} />
					<ShownSelect placeholder="Select Gender" select={item => {
						this.setState({
							...this.state,
							gender: item
						})
					}} items={[{
						value: "MALE",
						label: "Male"
					}, {
						value: "FEMALE",
						label: "Female"
					}]} value={this.state.gender} />
				</Modal>
			</div>
		)
	}

}

export default View