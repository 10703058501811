import {analytics, api} from '../Api/config';
import * as Crypto from "../../../Utils/Crypto";
import axios from 'axios';
import qs from 'qs'

const CancelToken = axios.CancelToken;
let isRefreshing = false;
axios.defaults.baseURL = api;
axios.defaults.timeout = 62500;
axios.interceptors.response.use(response=>{return response;}, function (error) {
	const err= error.response;
    if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
        err.config.__isRetryRequest = true;
        const originalRequest = err.config;
        if (!isRefreshing) {
            isRefreshing = true;
            getRefreshToken()
                .then(function (success) {
                    onRefreshed(success.data.access_token)
                })
                .catch(function(_error) {
                    localStorage.clear()
                    window.location.reload();
                });
        }
        return new Promise((resolve, reject) => {
            subscribeTokenRefresh(token => {
                originalRequest.headers['Authorization'] = 'Bearer ' + token;
                resolve(axios(originalRequest));
            });
        });
    }else{
        return Promise.reject(err);
    }
});

axios.interceptors.request.use(function (config) {
	const token = Crypto.get('token');
    if (token !== null && token!=="") {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
}, function (err) {
    return Promise.reject(err);
});

function getRefreshToken() {
    let refreshtoken = Crypto.get("refresh_token");
    if (!refreshtoken){
        localStorage.clear()
        window.location.reload();
    }
    return axios.post(`${api}auth/token/refresh`,{
        token:refreshtoken
    });
}


const Cancellable = (promise,mCancel = false) => {
	let hasCompleted = false;
	const wrappedPromise = new Promise((resolve, reject) => {
		promise.then(
			val => {
				if (hasCompleted) {
					reject({isCanceled: true})
				} else {
					hasCompleted = true;
					resolve(val)
				}
			},
			error => {
				if (hasCompleted) {
					reject({isCanceled: true})
				} else {
					reject(error);
				}
			}
		);
	});
	return {
		promise: wrappedPromise,
		cancel() {
			hasCompleted = true;
			if(mCancel){
				mCancel()
			}
		},
		isCancellable() {
			return !hasCompleted;
		}
	};
};

const setTokens = (token) =>{
    Crypto.set('token',token);
}

const get = (url, cb, data = {}) => {
	let cancel = false;
	let cancelToken = new CancelToken(function executor(c) {
		cancel = c;
	});
	let cancellable = Cancellable(
		axios.get(url, {
			params: data,
			paramsSerializer: params => {
				return qs.stringify(params, {arrayFormat: 'repeat'})
			},
			cancelToken
		}),cancel
	);
	cancellable.promise.then(res => {
		cb(null, res.data)
	}).catch(err => {
		if (err && !err.isCanceled) {
			cb(err)
		}
	});
	return cancellable;
}

const post = (url, data, cb) => {
	let cancellable = Cancellable(
		axios.post(url, data)
	);
	cancellable.promise.then(res => {
		if (res)
			cb(null, res.data)
	}).catch(err => {
		if (err && !err.isCanceled) {
			cb(err)
		}
	});
	return cancellable;
}

const patch = (url, data, cb) => {
	let cancellable = Cancellable(
		axios.patch(url, data)
	);
	cancellable.promise.then(res => {
		cb(null, res.data)
	}).catch(err => {
		if (err && !err.isCanceled) {
			cb(err)
		}
	});
	return cancellable;
}

const put =(url,data,cb)=>{
    axios.put(url,data).then(res=>{
        cb(null,res.data)
    }).catch(err=>cb(err,null))
}

let refreshSubscribers = [];

const subscribeTokenRefresh=(cb)=>{
    refreshSubscribers.push(cb);
}

const onRefreshed=(token)=>{
    setTokens(token);
    isRefreshing=false;
    refreshSubscribers=refreshSubscribers.filter(cb => {
        cb(token);
        return false;
    });
}




const analyticsApi= (url,cb,data={})=>{
    let cancellable = Cancellable(
	axios.get(`${analytics}${url}`,{
		params:data,
		paramsSerializer: params => {
			return qs.stringify(params, {arrayFormat: 'repeat'})
		}
    })
    )
    
    cancellable.promise.then(res => {
		cb(null, res.data)
	}).catch(err => {
		if (err && !err.isCanceled) {
			cb(err)
		}
	});
	return cancellable;
}

export default axios;
export {
    post,get,axios,patch,put,analyticsApi
}
