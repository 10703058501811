import React from 'react'
import {TableActions} from "../../../libs/Table/TableAction";
import TripDetails from './TripDetails';

class HotelRequestItem extends React.Component {

	state = {
		showSidePane: false,
	}

	handleCloseSidePane = () => {
        this.setState({ showSidePane: false });
    }

	render() {
		let item = this.props.data;
		let click = () => {
			this.props.history.push(
				`/app/hotel/search?requestId=${item.id}`
			)
		}
		if(this.props.closed){
			return (
				<div className="fintrip-department-item table-item" style={{
					borderBottom: "1px solid #dedede",
					display: 'flex',
					cursor: 'pointer',
					flexDirection: 'row',
					padding: '12px 0'
				}}>
					<div style={{flex: 1, alignItems: 'center'}} className="linear-layout horizontal">
						<div>
							<img src={item.employee.image} alt="employee" style={{
								width: 30, height: 30, borderRadius: 15,
								marginRight: 8
							}}/>
						</div>
						<div>
							<p className="top">{item.employee.name}</p>
							<p className="bottom">{item.employee.email}</p>
							<p className="bottom">{item.employee.code}</p>
						</div>
					</div>
					<div style={{
						flex: 1
					}}>
						<p style={{
							fontWeight: 700,
							fontSize: 14,
							textAlign: 'center'
						}} className="top">Hotel</p>
						<p style={{textAlign: 'center'}} className="bottom">{item.checkIn} - {item.checkOut}</p>
						<p  style={{
							textAlign: 'center'
						}} className="bottom">Location: {item.cityName}</p>
					</div>
					<div style={{flex:1}}>
						<p style={{
							fontWeight: 700,
							fontSize: 14,
							textAlign: 'center'
						}} className="top">
							{item.status}
						</p>
					</div>
				</div>
			)
		}
		return (
			<>
			{
				this.state.showSidePane ?
					<TripDetails onClose={(this.handleCloseSidePane)} tripId={item.tripId}/>
				:null
			}
			<div className="fintrip-department-item table-item"
			onClick={()=>{this.setState({ showSidePane: !this.state.showSidePane })}}
			style={{
				borderBottom: "1px solid #dedede",
				display: 'flex',
				cursor: 'pointer',
				flexDirection: 'row',
				padding: '12px 0'
			}}>
				<div style={{flex: 1, alignItems: 'center'}} className="linear-layout horizontal">
					<div>
						<img src={item.employee.image} alt="employee" style={{
							width: 30, height: 30, borderRadius: 15,
							marginRight: 8
						}}/>
					</div>
					<div>
						<p className="top">{item.employee.name}</p>
						<p className="bottom">{item.employee.email}</p>
						<p className="bottom">{item.employee.code}</p>
					</div>
				</div>
				<div style={{
					flex: 1
				}}>
					<p style={{
						fontWeight: 700,
						fontSize: 14
					}} className="top">Hotel</p>
					<p className="bottom">{item.checkIn} - {item.checkOut}</p>
					<p className="bottom">Location: {item.cityName}</p>
				</div>
				<div style={{flex: 1, alignItems: 'center'}} className="linear-layout horizontal">
					<p style={{
						fontWeight: 700,
						fontSize: 14
					}} className="top">{item.tripId}</p>
				</div>
				<div style={{flex:1.4}} className="right-actions">
					<TableActions items={[
						{
							name: "Book",
							click
						}, 
						{
							name: "Close Booking",
							click: () => {
								this.props.onClose({
									id:item.id,
									service:'hotels'
								})
							}
						},
						{
							name: "Decline",
							click: () => {
								this.props.onDecline({
									id:item.id,
									service:"hotels"
								})
							}
						},]}/>
					<p onClick={click} className="hoverable">Book</p>
				</div>
			</div>
		</>
		)
	}

}

export {
	HotelRequestItem
}
