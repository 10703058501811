import React from 'react'
import {TableActions} from "../../../libs/Table/TableAction";
import TripDetails from './TripDetails';

class FlightRequestItem extends React.Component {	

	state = {
		showSidePane: false,
	}

	handleCloseSidePane = () => {
        this.setState({ showSidePane: false });
    }

	render() {
		
		let item = this.props.data;
		let onwards = () => {
			this.props.history.push(
				`/app/flights/search?requestId=${item.id}`
			)
		}
		let round = () => {
			this.props.history.push(
				`/app/flights/search?requestId=${item.id}&type=return`
			)
		}

		let actions = [];

		if(item.onwardJourney){
			actions.push({
				name : "Book Onwards",
				click : onwards
			})
			
		}

		if(item.returnJourney){
			actions.push({
				name : "Book Return",
				click : round
			})
		}

		actions.push({
			name: "Close Booking",
			click:()=>{
				this.props.onClose({
					id:item.id,
					service:'flight'
				})
			}
		})
		actions.push({
			name: "Decline",
			click:()=>{
				this.props.onDecline({
					id:item.id,
					service:"flight"
				})
			}
		})
	
		if(this.props.closed){
			return (
				<div className="fintrip-department-item table-item" style={{
					borderBottom: "1px solid #dedede",
					display: 'flex',
					cursor: 'pointer',
					flexDirection: 'row',
					padding: '12px 0'
				}}>
					<div style={{flex: 1, alignItems: 'center'}} className="linear-layout horizontal">
						<div>
							<img src={item.employee.image} alt="employee" style={{
								width: 30, height: 30, borderRadius: 15,
								marginRight: 8
							}}/>
						</div>
						<div>
							<p className="top">{item.employee.name}</p>
							<p className="bottom">{item.employee.email}</p>
							<p className="bottom">{item.employee.code}</p>
						</div>
					</div>
					<div style={{
						flex: 1
					}}>
						<p style={{
							fontWeight: 700,
							fontSize: 14,
							textAlign: 'center'
						}} className="top">Flight</p>
						<p  style={{
							textAlign: 'center'
						}} className="bottom">
							{ item.originName } - {item.destinationName}
						</p>
						<p  style={{
							textAlign: 'center'
						}} className="bottom">
							{ item.returnDate?`${item.departureDate} - ${item.returnDate}` :`${item.departureDate}` }
						</p>
					</div>
					<div style={{flex:1}}>
						<p style={{
							fontWeight: 700,
							fontSize: 14,
							textAlign: 'center'
						}} className="top">{
							item.status
						}</p>
					</div>
				</div>
			)
		}
		return (
			<>

			{
				this.state.showSidePane ?
					<TripDetails onClose={(this.handleCloseSidePane)} tripId={item.tripId}/>
				:null
			}
			<div className="fintrip-department-item table-item" 
			onClick={()=>{this.setState({ showSidePane: !this.state.showSidePane })}}
			style={{
				borderBottom: "1px solid #dedede",
				display: 'flex',
				cursor: 'pointer',
				flexDirection: 'row',
				padding: '12px 0'
			}}>
				<div style={{flex: 1, alignItems: 'center'}} className="linear-layout horizontal">
					<div>
						<img src={item.employee.image} alt="employee" style={{
							width: 30, height: 30, borderRadius: 15,
							marginRight: 8
						}}/>
					</div>
					<div>
						<p className="top">{item.employee.name}</p>
						<p className="bottom">{item.employee.email}</p>
						<p className="bottom">{item.employee.code}</p>
					</div>
				</div>
				<div style={{
					flex: 1
				}}>
					<p style={{
						fontWeight: 700,
						fontSize: 14
					}} className="top">Flight</p>
					<p className="bottom">
						{ item.originName } - {item.destinationName}
					</p>
					<p className="bottom">
						{ item.returnDate?`${item.departureDate} - ${item.returnDate}` :`${item.departureDate}` }
					</p>
				</div>
				<div style={{flex: 1, alignItems: 'center'}} className="linear-layout horizontal">
					<p style={{
						fontWeight: 700,
						fontSize: 14
					}} className="top">{item.tripId}</p>
				</div>
				<div style={{flex:1.4}} className="right-actions">
					<TableActions items={actions}/>
					{item.returnJourney?<p onClick={round} className="hoverable">Return</p>:null}
					{item.onwardJourney?<p onClick={onwards} className="hoverable">Onwards</p>:null}
				</div>
			</div>
			</>
		)
	}

}

export {
	FlightRequestItem
}
